const TEXT_MESSAGE = {
  hero_h1: {
    ko: `최신 기술에 기반한 개발 솔루션`,
    en: `Experience the Newest Trend in the IT Industry`,
  },
  hero_h2: {
    ko: `김앤황 데이터사이언스는 고객에게 최적의 해결 방안을 제공하는
        맞춤형 개발 솔루션 기업입니다.`,
    en: `Kim & Hwang Data Science provides you the finest software solution
        based on our experience and cutting-edge technology`,
  },
  hero_link_project: { ko: `최신 프로젝트 보기`, en: `Explore our projects` },
  service_infos: [
    {
      title: {
        ko: `데이터 분석`,
        en: `Data Analysis`,
      },
      info: {
        ko: `머신 러닝, AI, 텍스트 마이닝, 주식 분석 분야에서 전문성을 가지고
              있으며, 대기업 빅데이터를 분석한 경험과 역량을 가지고 있습니다.`,
        en: `Our expertise guarantees you the best result in the field of
              machine learning, AI, text mining, stock analysis, and big data.`,
      },
    },
    {
      title: {
        ko: `웹 서비스 개발`,
        en: `Web Development`,
      },
      info: {
        ko: `최신 기술인 Gatsby와 React JS를 활용한 Frontend 개발 및 Serverless
              기반 Backend 개발 서비스를 제공합니다.`,
        en: `We provide you the most up-to-date web development service, with
              Gatsby and React JS based frontend and serverless-based backend
              development.`,
      },
    },
    {
      title: {
        ko: `설문 플랫폼 개발`,
        en: `Survey Platform`,
      },
      info: {
        ko: `비대면 강의, 고객 설문, 선호도 조사 등 다양한 분야에서 대규모 유저
              수를 감당할 수 있는 안정적인 플랫폼을 구현합니다.`,
        en: `Experienced in developing survey platforms for online courses 
        and consumer surveys, with stable performance even under high traffic.`,
      },
    },
  ],
  service_contact: {
    ko: `사업 문의: `,
    en: `Contact us at: `,
  },
  about_title_p: {
    ko: `김앤황데이터사이언스 주식회사(Kim & Hwang Data Science Corp.)는 2018년
          설립되었으며, 데이터 분석, 웹 서비스, 설문 플랫폼 등 다양한 개발
          분야에서 전문성을 가진 개발 솔루션 기업입니다.`,
    en: `Kim & Hwang Data Science Corp. was founded in 2018. With
          expertise in data analysis, web development, and survey platforms, we
          provide high tech software solutions to our clients.`,
  },
  projects_title: {
    ko: `사업 실적`,
    en: `Past Projects`,
  },
  projects_tech: {
    title: {
      ko: `보유 기술`,
      en: `Our Expertise`,
    },
    tech_cats: [
      {
        title: { ko: `데이터 분석`, en: `Data Analysis` },
        li: [
          {
            ko: `머신 러닝을 활용한 텍스트 마이닝`,
            en: `Text mining based on machine learning`,
          },
          {
            ko: `다양한 데이터 시각화 기술`,
            en: `Various visualization techniques`,
          },
          { ko: `웹사이트 데이터 수집`, en: `Web Site Data Scraping` },
        ],
      },
      {
        title: { ko: `웹 서비스 개발`, en: `Web Service Development` },
        li: [
          {
            ko: `JAMStack 기반 웹 개발`,
            en: `Web based on JAMStack`,
          },
          {
            ko: `Amazon AWS 서비스와 서버리스를 활용한 웹 과부하 문제 해결`,
            en: `Resolving web overload through Amazon AWS and serverless framework`,
          },
          {
            ko: `PWA 기준을 실현할 React Js, Gatsby Js 기술`,
            en: `Achieving PWA by using React Js and Gatsby Js`,
          },
        ],
      },
      {
        title: { ko: `설문 플랫폼 개발`, en: `Survey Platform Development` },
        li: [
          {
            ko: `서버리스 웹 서비스에 기반한 플랫폼 개발`,
            en: `Platform based on serverless web service`,
          },
          {
            ko: `JavaScript를 활용해 시간 제한, 음성 녹음/재생 등의 기능을 탑재한 설문 폼 제작`,
            en: `Creating survey forms with various options including timer and voice recording by JavaScript`,
          },
        ],
      },
    ],
  },
}

export default TEXT_MESSAGE
