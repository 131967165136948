import React, { useContext } from "react"
import styled from "styled-components"
//import Image from "gatsby-image"
//import { useStaticQuery, graphql } from "gatsby"
import { GatsbyContext } from "../context/context"
import TEXT_MESSAGE from "../constants/text-messages"

//penn-love-750x500.png
//philis1-3-2.jpg
// const query = graphql`
//   {
//     hero: file(relativePath: { eq: "philis1-3-2.jpg" }) {
//       childImageSharp {
//         fixed(height: 500) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
// ...GatsbyImageSharpFluid
const AboutPage = () => {
  // const {
  //   hero: {
  //     childImageSharp: { fixed },
  //   },
  // } = useStaticQuery(query)
  const { language } = useContext(GatsbyContext)
  return (
    <Wrapper className="page section section-center">
      {/* <div className="img">
          <Image fixed={fixed} />
        </div> */}
      <article>
        <div className="title">
          <h2>About Us</h2>
          <div className="underline"></div>
        </div>
        <p>
          {language === "ko"
            ? TEXT_MESSAGE.about_title_p.ko
            : TEXT_MESSAGE.about_title_p.en}
        </p>
      </article>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  h2 {
    font-size: 2rem;
  }
  p {
    line-height: 2;
    max-width: 60rem;
    margin-top: 2rem;
    color: var(--clr-black);
    text-align: left;
    font-size: 1.125rem;
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
`
export default AboutPage
