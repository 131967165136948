import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import AboutPage from "../components/AboutPage"
import Member from "../components/Member"
import { GatsbyContext } from "../context/context"

const AboutHomePage = ({ location, data }) => {
  //console.log("AboutHomePage location=", location)
  const { language } = useContext(GatsbyContext)
  const [postLang, setUsePoseLang] = useState(
    language === "ko" ? "post_ko" : "post_en"
  )
  useEffect(() => {
    //console.log("AboutHomePage useEffect language=", language)
    setUsePoseLang(language === "ko" ? "post_ko" : "post_en")
    if (language === "en") navigate(`/en/about`)
  }, [language])

  const {
    [postLang]: { nodes: childMdxes },
  } = data
  const members = childMdxes.map(child => {
    return child.childMdx
  })

  return (
    <Layout location={location}>
      <SEO title="About" description="about Kim and Hwang" />
      <AboutPage />
      <Wrapper>
        <section className="members">
          {members.map(member => {
            return <Member key={member.id} {...member} />
          })}
        </section>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    post_en: allFile(
      sort: { fields: relativeDirectory }
      filter: {
        sourceInstanceName: { eq: "members" }
        extension: { eq: "mdx" }
        name: { eq: "post_en" }
      }
    ) {
      nodes {
        childMdx {
          excerpt
          frontmatter {
            title
            author
            category
            date
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          body
        }
      }
    }
    post_ko: allFile(
      sort: { fields: relativeDirectory }
      filter: {
        sourceInstanceName: { eq: "members" }
        extension: { eq: "mdx" }
        name: { eq: "post_ko" }
      }
    ) {
      nodes {
        childMdx {
          excerpt
          frontmatter {
            title
            author
            category
            date
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          body
        }
      }
    }
  }
`

const Wrapper = styled.div`
  .members {
    display: grid;
    justify-content: center;
    width: 85vw;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 950px) {
    .members {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 1.25rem;
      justify-items: center;
    }
  }
  @media screen and (min-width: 769px) {
    .members {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1371px) {
    .members {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

export default AboutHomePage
